<template>
    <div class="container">


        <div class="education-direction-form mt-4">
            <h4 style="text-align: center">Направления подготовки</h4>

            <!-- Delete Education Direction Modal -->
            <div class="modal fade" id="deleteEducationDirectionModal" tabindex="-1"
                 aria-labelledby="deleteEducationDirectionModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="deleteEducationDirectionModalLabel">Удаление направления
                                подготовки</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">
                                <p>Вы точно хотите удалить направление подготовки?</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                    @click="deleteEducationDirection(deleteEducationDirectionId)">
                                Удалить
                            </button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Отменить
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal -->

            <div class="col-md-3">
                <label for="filterEducationDirections">Фильтрация по области образования</label>
                <select class="form-control form-select" id="filterEducationDirections"
                        @change="changeFilterEducationDirections">
                    <option v-for="(item, index) in [{id: 0, name: 'Все'}, ...ALL_EDUCATION_FIELD_NAMES]"
                            :value="item.id"
                            :key="index">{{item.name}}
                    </option>
                </select>
            </div>


            <div class="education-direction-table">
                <div class="row mt-4">

                    <div class="col-md-2">
                        №
                    </div>
<!--                    <div class="col-md-4">-->
<!--                        Область образования-->
<!--                    </div>-->
                    <div class="col-md-6">
                        Направление подготовки
                    </div>
                    <div class="col-md-2">
                        Редактировать
                    </div>
                    <div class="col-md-2">
                        Удалить
                    </div>

                </div>
                <hr>
                <div class="row" v-for="(item,index) in educationdirection_form.educationDirectionInfos" :key="index">

                    <div class="col-md-2">
                        {{ index+1 }}
                    </div>
<!--                    <div class="col-md-4">-->

<!--                        <select class="form-control form-select"-->
<!--                                @input="changeEducationDirectionData(item, 'education_field_id', $event)">-->
<!--                            <option v-for="(allEducationFieldNames, index) in ALL_EDUCATION_FIELD_NAMES"-->
<!--                                    :value="allEducationFieldNames.id" :key="index"-->
<!--                                    :selected="allEducationFieldNames.id == item.education_field_id"-->
<!--                                    :disabled="typeof item.edit_status=== 'undefined'">{{ allEducationFieldNames.name }}-->
<!--                            </option>-->
<!--                        </select>-->

<!--                    </div>-->

                    <div class="col-md-6">

          <input class="form-control" id="education_direction"
                    placeholder="Название направления подготовки" :value="item.name"
                    @input="changeEducationDirectionData(item, 'name', $event)"
                    :disabled="typeof item.edit_status=== 'undefined'"/>

                    </div>

                    <div class="col-md-2">
                        <button v-if="typeof item.edit_status=== 'undefined'" type="button" class="btn btn-warning"
                                @click="editEducationDirection(index)">
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                        </button>

                        <button v-if="item.edit_status == 1" type="button" class="btn btn-success"
                                @click="updateEducationDirection(item.id, index)">
                            <i class="fas fa-check" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="col-md-2">
                        <button type="button" class="btn btn-danger" data-bs-toggle="modal"
                                data-bs-target="#deleteEducationDirectionModal"
                                @click="openDeleteEducationDirectionModal(item.id)">
                            <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                    </div>

                    <hr>

                </div>
            </div>


            <nav v-if="pageCount" class="d-flex justify-content-center" aria-label="Page navigation example">


                <Paginate
                        v-model="page"
                        :page-count="pageCount"
                        :click-handler="paginateHandler"
                        :prev-text="'Предыдущий'"
                        :next-text="'Следующий'"
                        :container-class="'pagination'"
                        :page-class="'page-item'">
                </Paginate>

            </nav>

            <div class="form-row"
                 v-for="(newEducationDirectionInfo, index) in educationdirection_form.newEducationDirectionInfos"
                 :key="index">
                <div class="education-field-remove-item__button d-flex flex-row-reverse">
                    <i class="fa fa-times" @click="deleteRowEducationDirectionInfo(index)"></i>
                </div>

                <div class="form-group row mt-4">
                    <label for="education_field_id" class="col-md-3 col-form-label">Область образования</label>
                    <div class="col-md-9">
                        <select class="form-control form-select" id="education_field_id"
                                @input="changeNewEducationDirectionData(newEducationDirectionInfo, 'education_field_id', $event)">
                            <option v-for="(item, index) in ALL_EDUCATION_FIELD_NAMES"
                                    :value="item.id" :key="index">
                                {{item.name }}
                            </option>
                        </select>
                    </div>

                </div>

                <div class="form-group row mt-4">
                    <label for="education_direction_name" class="col-md-3 col-form-label">Название направления
                        подготовки</label>
                    <div class="col-md-9">
                        <input id="education_direction_name" type="text" class="form-control"
                               @input="changeNewEducationDirectionData(newEducationDirectionInfo, 'name', $event)">
                    </div>

                </div>

                <hr>

                <div class="row mt-4">
                    <div class="col-md-12 text-center">
                        <button class="btn btn-pill text-white btn-block btn-primary"
                                v-on:click="submitEducationDirection">Сохранить
                        </button>
                    </div>
                </div>

            </div>
            <div class="form-group">
                <button @click="addRowEducationDirectionInfo" type="button" class="btn btn-secondary"><i
                        class="fas fa-plus-circle"></i>
                    Добавить
                </button>
            </div>
        </div>


    </div>
</template>

<script>
    import Paginate from '../common/Paginate.vue'
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "EducationDirection",
        components: {
            Paginate
        },
        data() {
            return {
                deleteEducationDirectionId: '',
                page: +this.$route.query.page || 1,
                filterDirection: 0
            }
        },
        computed: {
            ...mapState('educationdirection', ['educationdirection_form', 'pageCount']),
            ...mapGetters('educationdirection', ['ALL_EDUCATION_FIELD_NAMES']),

            filterEducationDirections() {
                return this.ALL_EDUCATION_FIELD_NAMES
            }
        },

        methods: {
            ...mapActions('educationdirection', ['POST_EDUCATION_DIRECTION_DATA', 'PUT_EDUCATION_DIRECTION_DATA', 'GET_EDUCATION_DIRECTION_DATA', 'GET_EDUCATION_FIELD_NAMES', 'DELETE_EDUCATION_DIRECTION_DATA']),
            ...mapMutations('educationdirection', ['UPDATE_EDUCATION_DIRECTION_DATA', 'UPDATE_NEW_EDUCATION_DIRECTION_DATA', 'CHANGE_EDIT_STATUS_EDUCATION_DIRECTION_INFO',
                'DELETE_ROW_EDUCATION_DIRECTION_INFO', 'ADD_ROW_EDUCATION_DIRECTION_INFO']),
            async paginateHandler(page) {
                this.page = page
                await this.changePage()
            },
            async changePage() {
                this.$router.push(`${this.$route.path}?page=${this.page}&field_id=${this.filterDirection}`)
                await this.GET_EDUCATION_DIRECTION_DATA({page: this.page, field_id: this.filterDirection})
            },
            async deleteEducationDirection(id) {
                await this.DELETE_EDUCATION_DIRECTION_DATA(id);
                this.$message({title: 'Удаление', text: 'Успешное удаление'})
                await this.GET_EDUCATION_DIRECTION_DATA();
            },
            async editEducationDirection(index) {
                this.CHANGE_EDIT_STATUS_EDUCATION_DIRECTION_INFO(index)

                //this.editFileId = file_id;
                //console.log(this.editFileId, "EDIT FILE ID")
            },
            async openDeleteEducationDirectionModal(id) {
                this.deleteEducationDirectionId = id;
                console.log(this.deleteEducationDirectionId, "DELETE FILE ID")
            },
            async updateEducationDirection(id, index) {
                console.log(id, 'KSGSGSG')
                delete this.educationdirection_form.educationDirectionInfos[index].edit_status;
                await this.PUT_EDUCATION_DIRECTION_DATA(id)
            },
            deleteRowEducationDirectionInfo(index) {
                this.DELETE_ROW_EDUCATION_DIRECTION_INFO(index);
            },
            addRowEducationDirectionInfo() {
                this.ADD_ROW_EDUCATION_DIRECTION_INFO();
            },
            submitEducationDirection() {


                this.POST_EDUCATION_DIRECTION_DATA().then(res => {
                    if (res.success == true) {
                        this.GET_EDUCATION_DIRECTION_DATA();
                        this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
                    } else {
                        let errorText = '';
                        for (let err of res.errors[0]) {
                            errorText += err.message + '\n'
                        }
                        this.$error({title: 'Ошибка при добавлении', text: errorText})
                    }
                })
            },
            changeEducationDirectionData(item, property, e, val = 'value') {
                const value = e.target[val]
                console.log(value, "value")
                this.UPDATE_EDUCATION_DIRECTION_DATA({item, property, value})
            },
            changeNewEducationDirectionData(item, property, e, val = 'value') {
                const value = e.target[val]
                console.log(value, "value")
                this.UPDATE_NEW_EDUCATION_DIRECTION_DATA({item, property, value})
            },
            async changeFilterEducationDirections(e) {
                this.filterDirection = e.target.value
                await this.changePage()
            },
        },

        created() {
            this.GET_EDUCATION_DIRECTION_DATA({page: this.page, field_id: this.field_id});
            this.GET_EDUCATION_FIELD_NAMES();
        }
    }
</script>

<style scoped>

</style>